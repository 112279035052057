import NavBar from "../../components/NavBar";

export default function UnauthorizedPage() {
  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row mt-3">
          <h1 className="text-center">
            You are unauthorized to access this page.
          </h1>
        </div>
      </div>
    </>
  );
}
