/**
 * @file Slice for managing the state of new asset insertions
 */

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AssetType } from "../assets";

export type Coordinates = [number, number]; // TODO Define this elsewhere

interface NewAssetState {
  coordinates: Coordinates[];
  numCoords: number;
  assetType: AssetType | null;
}

const initialState: NewAssetState = {
  coordinates: [],
  numCoords: 0,
  assetType: null,
};

export const newAsset = createSlice({
  name: "newAsset",
  initialState,
  reducers: {
    setCoordinates: (state, { payload }: PayloadAction<Coordinates[]>) => {
      state.coordinates = payload;
      state.numCoords = payload.length;
    },
    addCoordinates: (state, { payload }: PayloadAction<Coordinates>) => {
      state.coordinates.push(payload);
      state.numCoords++;
    },
    removeCoordinates: (state) => {
      state.coordinates.pop();
      state.numCoords--;
    },
    updateCoordinates: (
      state,
      { payload }: PayloadAction<UpdateCoordinatesPayload>,
    ) => {
      state.coordinates[payload.i] = payload.coords;
    },
    setAssetType(
      state,
      { payload }: PayloadAction<NewAssetState["assetType"]>,
    ) {
      state.assetType = payload;
    },
    clearCoordinates: (state) => {
      state.coordinates = [];
      state.numCoords = 0;
    },
  },
});

interface UpdateCoordinatesPayload {
  i: number;
  coords: Coordinates;
}

export default newAsset.reducer;
export const {
  setCoordinates,
  addCoordinates,
  updateCoordinates,
  setAssetType,
  removeCoordinates,
  clearCoordinates,
} = newAsset.actions;
