import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import theme from "./theme";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import "bootstrap/dist/js/bootstrap";
import { ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "react-oidc-context";
import { authProviderProps } from "./auth";
import "./dayjs";

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_KEY);

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider {...authProviderProps}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </AuthProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
