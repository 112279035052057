import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AssetType } from "../assets";

/**
 * Stores filter configuration for metrics page
 */

interface MetricsFilterState {
  wharvesFilter: string[];
  assetTypesFilter: AssetType[];
}

const initialState: MetricsFilterState = {
  wharvesFilter: [],
  assetTypesFilter: [],
};

export const mapSlice = createSlice({
  name: "metricsFilter",
  initialState,
  reducers: {
    setWharvesFilter: (
      state,
      { payload: wharves }: PayloadAction<string[]>,
    ) => {
      state.wharvesFilter = wharves;
    },
    setAssetTypesFilter: (
      state,
      { payload: assetTypes }: PayloadAction<AssetType[]>,
    ) => {
      state.assetTypesFilter = assetTypes;
    },
  },
});

export default mapSlice.reducer;
export const { setAssetTypesFilter, setWharvesFilter } = mapSlice.actions;
