/**
 * @file Used to persist map state between site navigation
 */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type Coordinates = [number, number];
type MapFilterPayloadKey =
  | "piles"
  | "capBeams"
  | "requiredInspections"
  | "showDecommissioned";

interface MapState {
  filters: Record<MapFilterPayloadKey, boolean>;
  center: Coordinates | null;
  zoom: number | null;
  rotation: number;
}

interface MapFilterPayload {
  name: MapFilterPayloadKey;
  val: boolean;
}

const initialState: MapState = {
  filters: {
    piles: true,
    capBeams: true,
    requiredInspections: false,
    showDecommissioned: false,
  },
  center: null,
  zoom: null,
  rotation: 0,
};

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setFilter: (state, { payload }: PayloadAction<MapFilterPayload>) => {
      state.filters[payload.name] = payload.val;
    },
    setZoom: (state, { payload }: PayloadAction<number>) => {
      state.zoom = payload;
    },
    setCenter: (state, { payload }: PayloadAction<Coordinates>) => {
      state.center = payload;
    },
    setRotation: (state, { payload }: PayloadAction<number>) => {
      state.rotation = payload;
    },
  },
});

export default mapSlice.reducer;
export const { setFilter, setZoom, setCenter, setRotation } = mapSlice.actions;
