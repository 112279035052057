/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type Asset = {
  coordinates: Array<Coordinates>;
  id: Scalars['ID']['output'];
  inCommission: Scalars['Boolean']['output'];
  inspectionRequired: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  openspaceLink: Maybe<Scalars['String']['output']>;
};

export enum AssetType {
  Bollard = 'BOLLARD',
  CapBeam = 'CAP_BEAM',
  Pile = 'PILE'
}

export type AverageConditionMetric = {
  __typename?: 'AverageConditionMetric';
  condition: Scalars['Float']['output'];
  date: Scalars['String']['output'];
};

export type CapBeam = Asset & {
  __typename?: 'CapBeam';
  bent: Scalars['Int']['output'];
  capacityUtilization: Maybe<Scalars['Float']['output']>;
  condition: Maybe<Scalars['Float']['output']>;
  coordinates: Array<Coordinates>;
  id: Scalars['ID']['output'];
  inCommission: Scalars['Boolean']['output'];
  inspectionRequired: Scalars['Boolean']['output'];
  inspections: Array<CapBeamInspection>;
  name: Scalars['String']['output'];
  openspaceLink: Maybe<Scalars['String']['output']>;
  properties: Array<CapBeamProperty>;
  rowSpan: Array<Scalars['String']['output']>;
  side: Scalars['String']['output'];
  timeline: Array<TimelineEvent>;
  wharf: Wharf;
};

export type CapBeamConnection = {
  __typename?: 'CapBeamConnection';
  edges: Array<CapBeamEdge>;
  totalCount: Scalars['Int']['output'];
};

export type CapBeamDecay = {
  __typename?: 'CapBeamDecay';
  depth: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  length: Maybe<Scalars['Int']['output']>;
  location: Scalars['String']['output'];
  span: Array<Scalars['String']['output']>;
  width: Scalars['Int']['output'];
};

export type CapBeamDecayInput = {
  depth: Scalars['Int']['input'];
  length: InputMaybe<Scalars['Int']['input']>;
  location: Scalars['String']['input'];
  span: Array<Scalars['String']['input']>;
  width: Scalars['Int']['input'];
};

export type CapBeamDefaultProperty = {
  __typename?: 'CapBeamDefaultProperty';
  breadth: Scalars['Int']['output'];
  depth: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  wharf: Scalars['String']['output'];
};

export type CapBeamDefaultPropertyInput = {
  breadth: Scalars['Int']['input'];
  depth: Scalars['Int']['input'];
  wharfId: Scalars['ID']['input'];
};

export type CapBeamEdge = {
  __typename?: 'CapBeamEdge';
  node: CapBeam;
};

export type CapBeamInput = {
  bent: Scalars['Int']['input'];
  coordinates: Array<Array<Scalars['Float']['input']>>;
  rowSpan: Array<Scalars['String']['input']>;
  side: Side;
  wharfId: Scalars['ID']['input'];
};

export type CapBeamInspection = Inspection & {
  __typename?: 'CapBeamInspection';
  decays: Array<CapBeamDecay>;
  id: Scalars['ID']['output'];
  inspectedBy: User;
  inspectionDate: Scalars['String']['output'];
  steelCondition: Maybe<Scalars['String']['output']>;
};

export type CapBeamInspectionInput = {
  capBeamId: Scalars['ID']['input'];
  decays: Array<CapBeamDecayInput>;
  inspectionDate: InputMaybe<Scalars['String']['input']>;
  steelCondition: InputMaybe<Scalars['String']['input']>;
};

export type CapBeamLoad = {
  __typename?: 'CapBeamLoad';
  bendingLoad: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  shearLoad: Scalars['Float']['output'];
  wharf: Wharf;
};

export type CapBeamLoadInput = {
  bendingLoad: Scalars['Float']['input'];
  shearLoad: Scalars['Float']['input'];
  wharfId: Scalars['ID']['input'];
};

export type CapBeamProperty = Property & {
  __typename?: 'CapBeamProperty';
  appointedBy: User;
  appointedOn: Scalars['String']['output'];
  breadth: Scalars['Int']['output'];
  depth: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
};

export type CapBeamPropertyInput = {
  appointedOn: InputMaybe<Scalars['String']['input']>;
  breadth: Scalars['Int']['input'];
  capBeamId: Scalars['ID']['input'];
  depth: Scalars['Int']['input'];
};

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type Document = {
  __typename?: 'Document';
  context: Maybe<Scalars['String']['output']>;
  fileUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  uploadedBy: User;
  uploadedDate: Scalars['String']['output'];
};

export type DocumentInput = {
  assetId: Scalars['ID']['input'];
  assetType: AssetType;
  context: InputMaybe<Scalars['String']['input']>;
  filename: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type Inspection = {
  id: Scalars['ID']['output'];
  inspectedBy: User;
  inspectionDate: Scalars['String']['output'];
};

export type InspectionFrequency = {
  __typename?: 'InspectionFrequency';
  assetType: AssetType;
  id: Scalars['ID']['output'];
  period: Scalars['Int']['output'];
  wharf: Wharf;
};

export type InspectionFrequencyInput = {
  assetType: AssetType;
  period: Scalars['Int']['input'];
  wharfId: Scalars['ID']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createCapBeam: CapBeam;
  createCapBeamDefaultProperty: CapBeamDefaultProperty;
  createCapBeamInspection: CapBeamInspection;
  createCapBeamLoad: CapBeamLoad;
  createCapBeamProperty: CapBeamProperty;
  createDocument: Document;
  createInspectionFrequency: InspectionFrequency;
  createNote: Note;
  createPile: Pile;
  createPileDefaultProperty: PileDefaultProperty;
  createPileInspection: PileInspection;
  createPileLoad: PileLoad;
  createPileProperty: PileProperty;
  deleteAsset: Scalars['Boolean']['output'];
  deleteCapBeam: Scalars['Boolean']['output'];
  deleteCapBeamDefaultProperty: Scalars['Boolean']['output'];
  deleteCapBeamInspection: Scalars['Boolean']['output'];
  deleteCapBeamLoad: Scalars['Boolean']['output'];
  deleteCapBeamProperty: Scalars['Boolean']['output'];
  deleteDocument: Scalars['Boolean']['output'];
  deleteInspectionFrequency: Scalars['Boolean']['output'];
  deleteNote: Scalars['Boolean']['output'];
  deletePile: Scalars['Boolean']['output'];
  deletePileDefaultProperty: Scalars['Boolean']['output'];
  deletePileInspection: Scalars['Boolean']['output'];
  deletePileLoad: Scalars['Boolean']['output'];
  deletePileProperty: Scalars['Boolean']['output'];
  inviteUser: Scalars['Boolean']['output'];
  updateOpenspaceLink: Scalars['Boolean']['output'];
  updateUserRole: User;
};


export type MutationCreateCapBeamArgs = {
  input: CapBeamInput;
};


export type MutationCreateCapBeamDefaultPropertyArgs = {
  input: CapBeamDefaultPropertyInput;
};


export type MutationCreateCapBeamInspectionArgs = {
  input: CapBeamInspectionInput;
};


export type MutationCreateCapBeamLoadArgs = {
  input: CapBeamLoadInput;
};


export type MutationCreateCapBeamPropertyArgs = {
  input: CapBeamPropertyInput;
};


export type MutationCreateDocumentArgs = {
  input: DocumentInput;
};


export type MutationCreateInspectionFrequencyArgs = {
  input: InspectionFrequencyInput;
};


export type MutationCreateNoteArgs = {
  input: NoteInput;
};


export type MutationCreatePileArgs = {
  input: PileInput;
};


export type MutationCreatePileDefaultPropertyArgs = {
  input: PileDefaultPropertyInput;
};


export type MutationCreatePileInspectionArgs = {
  input: PileInspectionInput;
};


export type MutationCreatePileLoadArgs = {
  input: PileLoadInput;
};


export type MutationCreatePilePropertyArgs = {
  input: PilePropertyInput;
};


export type MutationDeleteAssetArgs = {
  assetId: Scalars['ID']['input'];
  assetType: AssetType;
};


export type MutationDeleteCapBeamArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCapBeamDefaultPropertyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCapBeamInspectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCapBeamLoadArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCapBeamPropertyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteInspectionFrequencyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteNoteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePileDefaultPropertyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePileInspectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePileLoadArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePilePropertyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationInviteUserArgs = {
  email: Scalars['String']['input'];
  role: Scalars['String']['input'];
};


export type MutationUpdateOpenspaceLinkArgs = {
  assetId: Scalars['ID']['input'];
  assetType: AssetType;
  link: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateUserRoleArgs = {
  role: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type Note = {
  __typename?: 'Note';
  context: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images: Array<Scalars['String']['output']>;
  postedBy: User;
  postedDate: Scalars['String']['output'];
};

export type NoteInput = {
  assetId: Scalars['ID']['input'];
  assetType: AssetType;
  context: InputMaybe<Scalars['String']['input']>;
  imageFilenames: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OverviewMetric = {
  __typename?: 'OverviewMetric';
  count: Scalars['Int']['output'];
  label: Scalars['String']['output'];
};

export type Pile = Asset & {
  __typename?: 'Pile';
  bent: Scalars['Int']['output'];
  broken: Scalars['Boolean']['output'];
  capacityUtilization: Maybe<Scalars['Float']['output']>;
  condition: Maybe<Scalars['Float']['output']>;
  coordinates: Array<Coordinates>;
  id: Scalars['ID']['output'];
  inCommission: Scalars['Boolean']['output'];
  inspectionRequired: Scalars['Boolean']['output'];
  inspections: Array<PileInspection>;
  name: Scalars['String']['output'];
  openspaceLink: Maybe<Scalars['String']['output']>;
  properties: Array<PileProperty>;
  row: Scalars['String']['output'];
  side: Maybe<Scalars['String']['output']>;
  timeline: Array<TimelineEvent>;
  wharf: Wharf;
};

export type PileConnection = {
  __typename?: 'PileConnection';
  edges: Array<PileEdge>;
  totalCount: Scalars['Int']['output'];
};

export type PileDefaultProperty = {
  __typename?: 'PileDefaultProperty';
  embedmentDepth: Maybe<Scalars['Int']['output']>;
  headDiameter: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  length: Scalars['Int']['output'];
  row: Scalars['String']['output'];
  wharf: Scalars['String']['output'];
};

export type PileDefaultPropertyInput = {
  embedmentDepth: Scalars['Int']['input'];
  headDiameter: Scalars['Int']['input'];
  length: Scalars['Int']['input'];
  row: Scalars['String']['input'];
  wharf: Scalars['String']['input'];
};

export type PileEdge = {
  __typename?: 'PileEdge';
  node: Pile;
};

export type PileInput = {
  bent: Scalars['Int']['input'];
  coordinates: Array<Array<Scalars['Float']['input']>>;
  row: Scalars['String']['input'];
  side: InputMaybe<Side>;
  wharfId: Scalars['ID']['input'];
};

export type PileInspection = Inspection & {
  __typename?: 'PileInspection';
  condition: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inspectedBy: User;
  inspectionDate: Scalars['String']['output'];
  jacketCondition: Maybe<Scalars['String']['output']>;
  seabedDiameter: Maybe<Scalars['Int']['output']>;
  wastedDiameter: Maybe<Scalars['Int']['output']>;
};

export type PileInspectionInput = {
  condition: Scalars['String']['input'];
  inspectionDate: InputMaybe<Scalars['String']['input']>;
  jacketCondition: InputMaybe<Scalars['String']['input']>;
  pileId: Scalars['ID']['input'];
  seabedDiameter: InputMaybe<Scalars['Int']['input']>;
  wastedDiameter: InputMaybe<Scalars['Int']['input']>;
};

export type PileLoad = {
  __typename?: 'PileLoad';
  id: Scalars['ID']['output'];
  load: Scalars['Float']['output'];
  row: Scalars['String']['output'];
  wharf: Wharf;
};

export type PileLoadInput = {
  load: Scalars['Float']['input'];
  row: Scalars['String']['input'];
  wharfId: Scalars['ID']['input'];
};

export type PileProperty = Property & {
  __typename?: 'PileProperty';
  appointedBy: User;
  appointedOn: Scalars['String']['output'];
  embedmentDepth: Maybe<Scalars['Int']['output']>;
  headDiameter: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  length: Scalars['Int']['output'];
};

export type PilePropertyInput = {
  appointedOn: InputMaybe<Scalars['String']['input']>;
  embedmentDepth: Scalars['Int']['input'];
  headDiameter: Scalars['Int']['input'];
  length: Scalars['Int']['input'];
  pileId: Scalars['ID']['input'];
};

export type Port = {
  __typename?: 'Port';
  center: Coordinates;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  users: Array<User>;
  wharves: Array<Wharf>;
  zoomLevel: Scalars['Int']['output'];
};

export type Property = {
  appointedBy: User;
  appointedOn: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type Query = {
  __typename?: 'Query';
  asset: Maybe<Asset>;
  averageConditionMetric: Array<AverageConditionMetric>;
  capBeam: Maybe<CapBeam>;
  capBeamDefaultProperties: Array<CapBeamDefaultProperty>;
  capBeamLoads: Array<CapBeamLoad>;
  capBeams: CapBeamConnection;
  documents: Array<Document>;
  inspectionFrequencies: Array<InspectionFrequency>;
  notes: Array<Note>;
  overviewMetric: Array<OverviewMetric>;
  pile: Maybe<Pile>;
  pileDefaultProperties: Array<PileDefaultProperty>;
  pileLoads: Array<PileLoad>;
  piles: PileConnection;
  port: Port;
  requiredInspectionsMetric: RequiredInspectionsMetric;
  user: Maybe<User>;
};


export type QueryAssetArgs = {
  assetId: Scalars['ID']['input'];
  assetType: AssetType;
};


export type QueryAverageConditionMetricArgs = {
  assetTypes: InputMaybe<Array<AssetType>>;
  wharves: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryCapBeamArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDocumentsArgs = {
  assetId: Scalars['ID']['input'];
  assetType: AssetType;
};


export type QueryNotesArgs = {
  assetId: Scalars['ID']['input'];
  assetType: AssetType;
};


export type QueryOverviewMetricArgs = {
  assetTypes: InputMaybe<Array<AssetType>>;
  bins?: Scalars['Int']['input'];
  wharves: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryPileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRequiredInspectionsMetricArgs = {
  assetTypes: InputMaybe<Array<AssetType>>;
  wharves: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};

export type RequiredInspectionsMetric = {
  __typename?: 'RequiredInspectionsMetric';
  notRequired: Scalars['Int']['output'];
  required: Scalars['Int']['output'];
};

export enum Side {
  East = 'EAST',
  North = 'NORTH',
  South = 'SOUTH',
  West = 'WEST'
}

export type TimelineEvent = {
  __typename?: 'TimelineEvent';
  capacityUtilization: Maybe<Scalars['Float']['output']>;
  condition: Maybe<Scalars['Float']['output']>;
  eventId: Scalars['ID']['output'];
  eventType: TimelineEventType;
};

export enum TimelineEventType {
  Inspection = 'INSPECTION',
  Property = 'PROPERTY'
}

export type User = {
  __typename?: 'User';
  companyId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
};

export type Wharf = {
  __typename?: 'Wharf';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type NewCapBeamInspectionFragment = { __typename?: 'CapBeamInspection', id: string, inspectionDate: string, steelCondition: string | null, decays: Array<{ __typename?: 'CapBeamDecay', width: number, depth: number, length: number | null, span: Array<string>, location: string }>, inspectedBy: { __typename?: 'User', firstName: string, lastName: string | null } } & { ' $fragmentName'?: 'NewCapBeamInspectionFragment' };

export type NewPileInspectionFragment = { __typename?: 'PileInspection', id: string, inspectionDate: string, wastedDiameter: number | null, seabedDiameter: number | null, condition: string, inspectedBy: { __typename?: 'User', firstName: string, lastName: string | null } } & { ' $fragmentName'?: 'NewPileInspectionFragment' };

export type DeletePileInspectionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeletePileInspectionMutation = { __typename?: 'Mutation', deletePileInspection: boolean };

export type UpdateOpenSpaceLinkMutationVariables = Exact<{
  assetType: AssetType;
  assetId: Scalars['ID']['input'];
  link: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateOpenSpaceLinkMutation = { __typename?: 'Mutation', updateOpenspaceLink: boolean };

export type DeleteCapBeamPropertyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteCapBeamPropertyMutation = { __typename?: 'Mutation', deleteCapBeamProperty: boolean };

export type CreateCapBeamPropertyMutationVariables = Exact<{
  input: CapBeamPropertyInput;
}>;


export type CreateCapBeamPropertyMutation = { __typename?: 'Mutation', createCapBeamProperty: { __typename?: 'CapBeamProperty', id: string } };

export type CreatePilePropertyMutationVariables = Exact<{
  input: PilePropertyInput;
}>;


export type CreatePilePropertyMutation = { __typename?: 'Mutation', createPileProperty: { __typename?: 'PileProperty', id: string } };

export type DeletePilePropertyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeletePilePropertyMutation = { __typename?: 'Mutation', deletePileProperty: boolean };

export type GetPortGeographicQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPortGeographicQuery = { __typename?: 'Query', port: { __typename?: 'Port', zoomLevel: number, id: string, center: { __typename?: 'Coordinates', latitude: number, longitude: number } } };

export type GetWharvesMultiSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWharvesMultiSelectQuery = { __typename?: 'Query', port: { __typename?: 'Port', id: string, wharves: Array<{ __typename?: 'Wharf', id: string, name: string }> } };

export type GetPortQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPortQuery = { __typename?: 'Query', port: { __typename?: 'Port', name: string, id: string } };

export type DeleteCapBeamInspectionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteCapBeamInspectionMutation = { __typename?: 'Mutation', deleteCapBeamInspection: boolean };

export type GetCapBeamInspectionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCapBeamInspectionsQuery = { __typename?: 'Query', capBeam: { __typename?: 'CapBeam', id: string, inspections: Array<{ __typename?: 'CapBeamInspection', inspectionDate: string, id: string, steelCondition: string | null, decays: Array<{ __typename?: 'CapBeamDecay', depth: number, width: number, length: number | null, span: Array<string>, id: string, location: string }>, inspectedBy: { __typename?: 'User', firstName: string, lastName: string | null } }> } | null };

export type CreateCapBeamInspectionMutationVariables = Exact<{
  input: CapBeamInspectionInput;
}>;


export type CreateCapBeamInspectionMutation = { __typename?: 'Mutation', createCapBeamInspection: { __typename?: 'CapBeamInspection', id: string, inspectionDate: string, steelCondition: string | null, decays: Array<{ __typename?: 'CapBeamDecay', width: number, depth: number, length: number | null, span: Array<string>, location: string }>, inspectedBy: { __typename?: 'User', firstName: string, lastName: string | null } } };

export type CreatePileInspectionMutationVariables = Exact<{
  input: PileInspectionInput;
}>;


export type CreatePileInspectionMutation = { __typename?: 'Mutation', createPileInspection: { __typename?: 'PileInspection', id: string, inspectionDate: string, wastedDiameter: number | null, seabedDiameter: number | null, condition: string, jacketCondition: string | null, inspectedBy: { __typename?: 'User', firstName: string, lastName: string | null } } };

export type GetPileInspectionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetPileInspectionsQuery = { __typename?: 'Query', pile: { __typename?: 'Pile', id: string, inspections: Array<{ __typename?: 'PileInspection', inspectionDate: string, id: string, condition: string, jacketCondition: string | null, seabedDiameter: number | null, wastedDiameter: number | null, inspectedBy: { __typename?: 'User', firstName: string, lastName: string | null } }> } | null };

export type GetCapBeamPropertiesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCapBeamPropertiesQuery = { __typename?: 'Query', capBeam: { __typename?: 'CapBeam', id: string, properties: Array<{ __typename?: 'CapBeamProperty', id: string, appointedOn: string, breadth: number, depth: number }> } | null };

export type GetPilePropertiesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetPilePropertiesQuery = { __typename?: 'Query', pile: { __typename?: 'Pile', id: string, properties: Array<{ __typename?: 'PileProperty', id: string, length: number, headDiameter: number, appointedOn: string, embedmentDepth: number | null }> } | null };

export type GetPileTimelineQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetPileTimelineQuery = { __typename?: 'Query', pile: { __typename?: 'Pile', id: string, inspections: Array<{ __typename?: 'PileInspection', id: string, inspectionDate: string }>, properties: Array<{ __typename?: 'PileProperty', id: string, appointedOn: string }>, timeline: Array<{ __typename?: 'TimelineEvent', capacityUtilization: number | null, condition: number | null, eventId: string, eventType: TimelineEventType }> } | null };

export type GetCapBeamTimelineQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCapBeamTimelineQuery = { __typename?: 'Query', capBeam: { __typename?: 'CapBeam', id: string, inspections: Array<{ __typename?: 'CapBeamInspection', id: string, inspectionDate: string }>, properties: Array<{ __typename?: 'CapBeamProperty', id: string, appointedOn: string }>, timeline: Array<{ __typename?: 'TimelineEvent', capacityUtilization: number | null, condition: number | null, eventId: string, eventType: TimelineEventType }> } | null };

export type GetCapBeamsTableQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCapBeamsTableQuery = { __typename?: 'Query', capBeams: { __typename?: 'CapBeamConnection', edges: Array<{ __typename?: 'CapBeamEdge', node: { __typename?: 'CapBeam', id: string, condition: number | null, name: string, bent: number, rowSpan: Array<string>, capacityUtilization: number | null, inspectionRequired: boolean, wharf: { __typename?: 'Wharf', name: string }, inspections: Array<{ __typename?: 'CapBeamInspection', inspectionDate: string, inspectedBy: { __typename?: 'User', firstName: string, lastName: string | null } }> } }> } };

export type GetPilesTableQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPilesTableQuery = { __typename?: 'Query', piles: { __typename?: 'PileConnection', edges: Array<{ __typename?: 'PileEdge', node: { __typename?: 'Pile', id: string, condition: number | null, name: string, bent: number, row: string, broken: boolean, capacityUtilization: number | null, inspectionRequired: boolean, wharf: { __typename?: 'Wharf', name: string }, inspections: Array<{ __typename?: 'PileInspection', wastedDiameter: number | null, seabedDiameter: number | null, inspectionDate: string, inspectedBy: { __typename?: 'User', firstName: string, lastName: string | null } }> } }> } };

export type GetInspectionFrequenciesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInspectionFrequenciesQuery = { __typename?: 'Query', inspectionFrequencies: Array<{ __typename?: 'InspectionFrequency', id: string, period: number, assetType: AssetType, wharf: { __typename?: 'Wharf', name: string } }> };

export type GetCapBeamsGeographicQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCapBeamsGeographicQuery = { __typename?: 'Query', capBeams: { __typename?: 'CapBeamConnection', edges: Array<{ __typename?: 'CapBeamEdge', node: { __typename?: 'CapBeam', id: string, condition: number | null, inCommission: boolean, name: string, openspaceLink: string | null, inspectionRequired: boolean, coordinates: Array<{ __typename?: 'Coordinates', latitude: number, longitude: number }> } }> } };

export type GetPilesGeographicQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPilesGeographicQuery = { __typename?: 'Query', piles: { __typename?: 'PileConnection', edges: Array<{ __typename?: 'PileEdge', node: { __typename?: 'Pile', id: string, condition: number | null, inCommission: boolean, name: string, openspaceLink: string | null, inspectionRequired: boolean, coordinates: Array<{ __typename?: 'Coordinates', latitude: number, longitude: number }> } }> } };

export type GetNotesQueryVariables = Exact<{
  assetId: Scalars['ID']['input'];
  assetType: AssetType;
}>;


export type GetNotesQuery = { __typename?: 'Query', notes: Array<{ __typename?: 'Note', id: string, postedDate: string, images: Array<string>, context: string | null, postedBy: { __typename?: 'User', firstName: string, lastName: string | null } }> };

export type DeleteAssetMutationVariables = Exact<{
  assetType: AssetType;
  assetId: Scalars['ID']['input'];
}>;


export type DeleteAssetMutation = { __typename?: 'Mutation', deleteAsset: boolean };

export type GetPilesGeoEditQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPilesGeoEditQuery = { __typename?: 'Query', piles: { __typename?: 'PileConnection', edges: Array<{ __typename?: 'PileEdge', node: { __typename?: 'Pile', id: string, inCommission: boolean, name: string, coordinates: Array<{ __typename?: 'Coordinates', latitude: number, longitude: number }> } }> } };

export type GetCapBeamsGeoEditQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCapBeamsGeoEditQuery = { __typename?: 'Query', capBeams: { __typename?: 'CapBeamConnection', edges: Array<{ __typename?: 'CapBeamEdge', node: { __typename?: 'CapBeam', id: string, inCommission: boolean, name: string, coordinates: Array<{ __typename?: 'Coordinates', latitude: number, longitude: number }> } }> } };

export type GetCapBeamDefaultPropertiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCapBeamDefaultPropertiesQuery = { __typename?: 'Query', capBeamDefaultProperties: Array<{ __typename?: 'CapBeamDefaultProperty', id: string, wharf: string, breadth: number, depth: number }> };

export type GetPileDefaultPropertiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPileDefaultPropertiesQuery = { __typename?: 'Query', pileDefaultProperties: Array<{ __typename?: 'PileDefaultProperty', id: string, length: number, row: string, wharf: string, headDiameter: number, embedmentDepth: number | null }> };

export type GetAssetQueryVariables = Exact<{
  type: AssetType;
  id: Scalars['ID']['input'];
}>;


export type GetAssetQuery = { __typename?: 'Query', asset: { __typename?: 'CapBeam', id: string, name: string, openspaceLink: string | null, coordinates: Array<{ __typename?: 'Coordinates', latitude: number, longitude: number }> } | { __typename?: 'Pile', id: string, name: string, openspaceLink: string | null, coordinates: Array<{ __typename?: 'Coordinates', latitude: number, longitude: number }> } | null };

export type GetPilesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPilesQuery = { __typename?: 'Query', piles: { __typename?: 'PileConnection', edges: Array<{ __typename?: 'PileEdge', node: { __typename?: 'Pile', row: string, bent: number, side: string | null, wharf: { __typename?: 'Wharf', id: string } } }> } };

export type GetCapBeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCapBeamsQuery = { __typename?: 'Query', capBeams: { __typename?: 'CapBeamConnection', edges: Array<{ __typename?: 'CapBeamEdge', node: { __typename?: 'CapBeam', rowSpan: Array<string>, bent: number, side: string, wharf: { __typename?: 'Wharf', id: string } } }> } };

export type DeleteCapBeamDefaultPropertyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteCapBeamDefaultPropertyMutation = { __typename?: 'Mutation', deleteCapBeamDefaultProperty: boolean };

export type NewCapBeamDefaultPropertyMutationVariables = Exact<{
  input: CapBeamDefaultPropertyInput;
}>;


export type NewCapBeamDefaultPropertyMutation = { __typename?: 'Mutation', createCapBeamDefaultProperty: { __typename?: 'CapBeamDefaultProperty', id: string } };

export type NewPileDefaultPropertyMutationVariables = Exact<{
  input: PileDefaultPropertyInput;
}>;


export type NewPileDefaultPropertyMutation = { __typename?: 'Mutation', createPileDefaultProperty: { __typename?: 'PileDefaultProperty', id: string } };

export type DeletePileDefaultPropertyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeletePileDefaultPropertyMutation = { __typename?: 'Mutation', deletePileDefaultProperty: boolean };

export type CreateInspectionFrequencyMutationVariables = Exact<{
  input: InspectionFrequencyInput;
}>;


export type CreateInspectionFrequencyMutation = { __typename?: 'Mutation', createInspectionFrequency: { __typename?: 'InspectionFrequency', id: string } };

export type DeleteInspectionFrequencyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteInspectionFrequencyMutation = { __typename?: 'Mutation', deleteInspectionFrequency: boolean };

export type CreateCapBeamLoadMutationVariables = Exact<{
  input: CapBeamLoadInput;
}>;


export type CreateCapBeamLoadMutation = { __typename?: 'Mutation', createCapBeamLoad: { __typename?: 'CapBeamLoad', id: string } };

export type DeleteCapBeamLoadMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteCapBeamLoadMutation = { __typename?: 'Mutation', deleteCapBeamLoad: boolean };

export type GetCapBeamLoadsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCapBeamLoadsQuery = { __typename?: 'Query', capBeamLoads: Array<{ __typename?: 'CapBeamLoad', id: string, bendingLoad: number, shearLoad: number, wharf: { __typename?: 'Wharf', name: string } }> };

export type CreatePileLoadMutationVariables = Exact<{
  input: PileLoadInput;
}>;


export type CreatePileLoadMutation = { __typename?: 'Mutation', createPileLoad: { __typename?: 'PileLoad', id: string } };

export type DeletePileLoadMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeletePileLoadMutation = { __typename?: 'Mutation', deletePileLoad: boolean };

export type GetPileLoadsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPileLoadsQuery = { __typename?: 'Query', pileLoads: Array<{ __typename?: 'PileLoad', id: string, load: number, row: string, wharf: { __typename?: 'Wharf', name: string } }> };

export type CreateCapBeamMutationVariables = Exact<{
  input: CapBeamInput;
}>;


export type CreateCapBeamMutation = { __typename?: 'Mutation', createCapBeam: { __typename?: 'CapBeam', id: string } };

export type CreatePileMutationVariables = Exact<{
  input: PileInput;
}>;


export type CreatePileMutation = { __typename?: 'Mutation', createPile: { __typename?: 'Pile', id: string } };

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteDocumentMutation = { __typename?: 'Mutation', deleteDocument: boolean };

export type GetDocumentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  assetType: AssetType;
}>;


export type GetDocumentsQuery = { __typename?: 'Query', documents: Array<{ __typename?: 'Document', id: string, uploadedDate: string, fileUrl: string, context: string | null, name: string, uploadedBy: { __typename?: 'User', firstName: string, lastName: string | null } }> };

export type CreateDocumentMutationVariables = Exact<{
  input: DocumentInput;
}>;


export type CreateDocumentMutation = { __typename?: 'Mutation', createDocument: { __typename?: 'Document', id: string, fileUrl: string } };

export type CreateNoteMutationVariables = Exact<{
  input: NoteInput;
}>;


export type CreateNoteMutation = { __typename?: 'Mutation', createNote: { __typename?: 'Note', id: string, images: Array<string> } };

export type DeleteNoteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteNoteMutation = { __typename?: 'Mutation', deleteNote: boolean };

export type GetPileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetPileQuery = { __typename?: 'Query', pile: { __typename?: 'Pile', condition: number | null, inspectionRequired: boolean } | null };

export type PileChangedFragment = { __typename?: 'Pile', condition: number | null, inspectionRequired: boolean } & { ' $fragmentName'?: 'PileChangedFragment' };

export type GetCapBeamQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCapBeamQuery = { __typename?: 'Query', capBeam: { __typename?: 'CapBeam', condition: number | null, inspectionRequired: boolean } | null };

export type CapBeamChangedFragment = { __typename?: 'CapBeam', condition: number | null, inspectionRequired: boolean } & { ' $fragmentName'?: 'CapBeamChangedFragment' };

export type InviteUserMutationVariables = Exact<{
  email: Scalars['String']['input'];
  role: Scalars['String']['input'];
}>;


export type InviteUserMutation = { __typename?: 'Mutation', inviteUser: boolean };

export type UpdateUserRoleMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  role: Scalars['String']['input'];
}>;


export type UpdateUserRoleMutation = { __typename?: 'Mutation', updateUserRole: { __typename?: 'User', id: string } };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', port: { __typename?: 'Port', users: Array<{ __typename?: 'User', email: string, firstName: string, lastName: string | null, id: string, role: string }> } };

export type GetAverageConditionMetricsQueryVariables = Exact<{
  wharves: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  assetTypes: InputMaybe<Array<AssetType> | AssetType>;
}>;


export type GetAverageConditionMetricsQuery = { __typename?: 'Query', averageConditionMetric: Array<{ __typename?: 'AverageConditionMetric', date: string, condition: number }> };

export type GetOverviewMetricQueryVariables = Exact<{
  wharves: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  assetTypes: InputMaybe<Array<AssetType> | AssetType>;
}>;


export type GetOverviewMetricQuery = { __typename?: 'Query', overviewMetric: Array<{ __typename?: 'OverviewMetric', label: string, count: number }> };

export type GetRequiredInspectionsMetricQueryVariables = Exact<{
  wharves: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  assetTypes: InputMaybe<Array<AssetType> | AssetType>;
}>;


export type GetRequiredInspectionsMetricQuery = { __typename?: 'Query', requiredInspectionsMetric: { __typename?: 'RequiredInspectionsMetric', required: number, notRequired: number } };

export const NewCapBeamInspectionFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"NewCapBeamInspection"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CapBeamInspection"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"inspectionDate"}},{"kind":"Field","name":{"kind":"Name","value":"steelCondition"}},{"kind":"Field","name":{"kind":"Name","value":"decays"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"width"}},{"kind":"Field","name":{"kind":"Name","value":"depth"}},{"kind":"Field","name":{"kind":"Name","value":"length"}},{"kind":"Field","name":{"kind":"Name","value":"span"}},{"kind":"Field","name":{"kind":"Name","value":"location"}}]}},{"kind":"Field","name":{"kind":"Name","value":"inspectedBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}}]}}]} as unknown as DocumentNode<NewCapBeamInspectionFragment, unknown>;
export const NewPileInspectionFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"NewPileInspection"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PileInspection"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"inspectionDate"}},{"kind":"Field","name":{"kind":"Name","value":"wastedDiameter"}},{"kind":"Field","name":{"kind":"Name","value":"seabedDiameter"}},{"kind":"Field","name":{"kind":"Name","value":"condition"}},{"kind":"Field","name":{"kind":"Name","value":"inspectedBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}}]}}]} as unknown as DocumentNode<NewPileInspectionFragment, unknown>;
export const PileChangedFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PileChanged"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Pile"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"condition"}},{"kind":"Field","name":{"kind":"Name","value":"inspectionRequired"}}]}}]} as unknown as DocumentNode<PileChangedFragment, unknown>;
export const CapBeamChangedFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CapBeamChanged"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CapBeam"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"condition"}},{"kind":"Field","name":{"kind":"Name","value":"inspectionRequired"}}]}}]} as unknown as DocumentNode<CapBeamChangedFragment, unknown>;
export const DeletePileInspectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeletePileInspection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deletePileInspection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<DeletePileInspectionMutation, DeletePileInspectionMutationVariables>;
export const UpdateOpenSpaceLinkDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateOpenSpaceLink"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"assetType"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AssetType"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"assetId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"link"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateOpenspaceLink"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"assetType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"assetType"}}},{"kind":"Argument","name":{"kind":"Name","value":"assetId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"assetId"}}},{"kind":"Argument","name":{"kind":"Name","value":"link"},"value":{"kind":"Variable","name":{"kind":"Name","value":"link"}}}]}]}}]} as unknown as DocumentNode<UpdateOpenSpaceLinkMutation, UpdateOpenSpaceLinkMutationVariables>;
export const DeleteCapBeamPropertyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteCapBeamProperty"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteCapBeamProperty"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<DeleteCapBeamPropertyMutation, DeleteCapBeamPropertyMutationVariables>;
export const CreateCapBeamPropertyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateCapBeamProperty"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CapBeamPropertyInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createCapBeamProperty"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreateCapBeamPropertyMutation, CreateCapBeamPropertyMutationVariables>;
export const CreatePilePropertyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreatePileProperty"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PilePropertyInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createPileProperty"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreatePilePropertyMutation, CreatePilePropertyMutationVariables>;
export const DeletePilePropertyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeletePileProperty"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deletePileProperty"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<DeletePilePropertyMutation, DeletePilePropertyMutationVariables>;
export const GetPortGeographicDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPortGeographic"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"port"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"center"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"latitude"}},{"kind":"Field","name":{"kind":"Name","value":"longitude"}}]}},{"kind":"Field","name":{"kind":"Name","value":"zoomLevel"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<GetPortGeographicQuery, GetPortGeographicQueryVariables>;
export const GetWharvesMultiSelectDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getWharvesMultiSelect"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"port"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"wharves"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode<GetWharvesMultiSelectQuery, GetWharvesMultiSelectQueryVariables>;
export const GetPortDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPort"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"port"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<GetPortQuery, GetPortQueryVariables>;
export const DeleteCapBeamInspectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteCapBeamInspection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteCapBeamInspection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<DeleteCapBeamInspectionMutation, DeleteCapBeamInspectionMutationVariables>;
export const GetCapBeamInspectionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCapBeamInspections"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"capBeam"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"inspections"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"inspectionDate"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"steelCondition"}},{"kind":"Field","name":{"kind":"Name","value":"decays"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"depth"}},{"kind":"Field","name":{"kind":"Name","value":"width"}},{"kind":"Field","name":{"kind":"Name","value":"length"}},{"kind":"Field","name":{"kind":"Name","value":"span"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"location"}}]}},{"kind":"Field","name":{"kind":"Name","value":"inspectedBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetCapBeamInspectionsQuery, GetCapBeamInspectionsQueryVariables>;
export const CreateCapBeamInspectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateCapBeamInspection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CapBeamInspectionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createCapBeamInspection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"inspectionDate"}},{"kind":"Field","name":{"kind":"Name","value":"steelCondition"}},{"kind":"Field","name":{"kind":"Name","value":"decays"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"width"}},{"kind":"Field","name":{"kind":"Name","value":"depth"}},{"kind":"Field","name":{"kind":"Name","value":"length"}},{"kind":"Field","name":{"kind":"Name","value":"span"}},{"kind":"Field","name":{"kind":"Name","value":"location"}}]}},{"kind":"Field","name":{"kind":"Name","value":"inspectedBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}}]}}]}}]} as unknown as DocumentNode<CreateCapBeamInspectionMutation, CreateCapBeamInspectionMutationVariables>;
export const CreatePileInspectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreatePileInspection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PileInspectionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createPileInspection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"inspectionDate"}},{"kind":"Field","name":{"kind":"Name","value":"wastedDiameter"}},{"kind":"Field","name":{"kind":"Name","value":"seabedDiameter"}},{"kind":"Field","name":{"kind":"Name","value":"condition"}},{"kind":"Field","name":{"kind":"Name","value":"jacketCondition"}},{"kind":"Field","name":{"kind":"Name","value":"inspectedBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}}]}}]}}]} as unknown as DocumentNode<CreatePileInspectionMutation, CreatePileInspectionMutationVariables>;
export const GetPileInspectionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPileInspections"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"inspections"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"inspectionDate"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"condition"}},{"kind":"Field","name":{"kind":"Name","value":"jacketCondition"}},{"kind":"Field","name":{"kind":"Name","value":"seabedDiameter"}},{"kind":"Field","name":{"kind":"Name","value":"wastedDiameter"}},{"kind":"Field","name":{"kind":"Name","value":"inspectedBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetPileInspectionsQuery, GetPileInspectionsQueryVariables>;
export const GetCapBeamPropertiesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCapBeamProperties"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"capBeam"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"properties"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"appointedOn"}},{"kind":"Field","name":{"kind":"Name","value":"breadth"}},{"kind":"Field","name":{"kind":"Name","value":"depth"}}]}}]}}]}}]} as unknown as DocumentNode<GetCapBeamPropertiesQuery, GetCapBeamPropertiesQueryVariables>;
export const GetPilePropertiesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPileProperties"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"properties"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"length"}},{"kind":"Field","name":{"kind":"Name","value":"headDiameter"}},{"kind":"Field","name":{"kind":"Name","value":"appointedOn"}},{"kind":"Field","name":{"kind":"Name","value":"embedmentDepth"}}]}}]}}]}}]} as unknown as DocumentNode<GetPilePropertiesQuery, GetPilePropertiesQueryVariables>;
export const GetPileTimelineDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPileTimeline"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"inspections"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"inspectionDate"}}]}},{"kind":"Field","name":{"kind":"Name","value":"properties"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"appointedOn"}}]}},{"kind":"Field","name":{"kind":"Name","value":"timeline"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"capacityUtilization"}},{"kind":"Field","name":{"kind":"Name","value":"condition"}},{"kind":"Field","name":{"kind":"Name","value":"eventId"}},{"kind":"Field","name":{"kind":"Name","value":"eventType"}}]}}]}}]}}]} as unknown as DocumentNode<GetPileTimelineQuery, GetPileTimelineQueryVariables>;
export const GetCapBeamTimelineDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCapBeamTimeline"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"capBeam"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"inspections"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"inspectionDate"}}]}},{"kind":"Field","name":{"kind":"Name","value":"properties"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"appointedOn"}}]}},{"kind":"Field","name":{"kind":"Name","value":"timeline"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"capacityUtilization"}},{"kind":"Field","name":{"kind":"Name","value":"condition"}},{"kind":"Field","name":{"kind":"Name","value":"eventId"}},{"kind":"Field","name":{"kind":"Name","value":"eventType"}}]}}]}}]}}]} as unknown as DocumentNode<GetCapBeamTimelineQuery, GetCapBeamTimelineQueryVariables>;
export const GetCapBeamsTableDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCapBeamsTable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"capBeams"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"condition"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"wharf"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"bent"}},{"kind":"Field","name":{"kind":"Name","value":"rowSpan"}},{"kind":"Field","name":{"kind":"Name","value":"capacityUtilization"}},{"kind":"Field","name":{"kind":"Name","value":"inspectionRequired"}},{"kind":"Field","name":{"kind":"Name","value":"inspections"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"inspectionDate"}},{"kind":"Field","name":{"kind":"Name","value":"inspectedBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetCapBeamsTableQuery, GetCapBeamsTableQueryVariables>;
export const GetPilesTableDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPilesTable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"piles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"condition"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"wharf"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"bent"}},{"kind":"Field","name":{"kind":"Name","value":"row"}},{"kind":"Field","name":{"kind":"Name","value":"broken"}},{"kind":"Field","name":{"kind":"Name","value":"capacityUtilization"}},{"kind":"Field","name":{"kind":"Name","value":"inspectionRequired"}},{"kind":"Field","name":{"kind":"Name","value":"inspections"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"wastedDiameter"}},{"kind":"Field","name":{"kind":"Name","value":"seabedDiameter"}},{"kind":"Field","name":{"kind":"Name","value":"inspectionDate"}},{"kind":"Field","name":{"kind":"Name","value":"inspectedBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetPilesTableQuery, GetPilesTableQueryVariables>;
export const GetInspectionFrequenciesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetInspectionFrequencies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"inspectionFrequencies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"period"}},{"kind":"Field","name":{"kind":"Name","value":"wharf"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"assetType"}}]}}]}}]} as unknown as DocumentNode<GetInspectionFrequenciesQuery, GetInspectionFrequenciesQueryVariables>;
export const GetCapBeamsGeographicDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCapBeamsGeographic"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"capBeams"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"coordinates"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"latitude"}},{"kind":"Field","name":{"kind":"Name","value":"longitude"}}]}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"condition"}},{"kind":"Field","name":{"kind":"Name","value":"inCommission"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"openspaceLink"}},{"kind":"Field","name":{"kind":"Name","value":"inspectionRequired"}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetCapBeamsGeographicQuery, GetCapBeamsGeographicQueryVariables>;
export const GetPilesGeographicDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPilesGeographic"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"piles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"coordinates"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"latitude"}},{"kind":"Field","name":{"kind":"Name","value":"longitude"}}]}},{"kind":"Field","name":{"kind":"Name","value":"condition"}},{"kind":"Field","name":{"kind":"Name","value":"inCommission"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"openspaceLink"}},{"kind":"Field","name":{"kind":"Name","value":"inspectionRequired"}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetPilesGeographicQuery, GetPilesGeographicQueryVariables>;
export const GetNotesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetNotes"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"assetId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"assetType"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AssetType"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notes"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"assetId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"assetId"}}},{"kind":"Argument","name":{"kind":"Name","value":"assetType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"assetType"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"postedBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"postedDate"}},{"kind":"Field","name":{"kind":"Name","value":"images"}},{"kind":"Field","name":{"kind":"Name","value":"context"}}]}}]}}]} as unknown as DocumentNode<GetNotesQuery, GetNotesQueryVariables>;
export const DeleteAssetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteAsset"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"assetType"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AssetType"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"assetId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteAsset"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"assetType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"assetType"}}},{"kind":"Argument","name":{"kind":"Name","value":"assetId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"assetId"}}}]}]}}]} as unknown as DocumentNode<DeleteAssetMutation, DeleteAssetMutationVariables>;
export const GetPilesGeoEditDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPilesGeoEdit"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"piles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"coordinates"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"latitude"}},{"kind":"Field","name":{"kind":"Name","value":"longitude"}}]}},{"kind":"Field","name":{"kind":"Name","value":"inCommission"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetPilesGeoEditQuery, GetPilesGeoEditQueryVariables>;
export const GetCapBeamsGeoEditDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCapBeamsGeoEdit"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"capBeams"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"coordinates"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"latitude"}},{"kind":"Field","name":{"kind":"Name","value":"longitude"}}]}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"inCommission"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetCapBeamsGeoEditQuery, GetCapBeamsGeoEditQueryVariables>;
export const GetCapBeamDefaultPropertiesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCapBeamDefaultProperties"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"capBeamDefaultProperties"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"wharf"}},{"kind":"Field","name":{"kind":"Name","value":"breadth"}},{"kind":"Field","name":{"kind":"Name","value":"depth"}}]}}]}}]} as unknown as DocumentNode<GetCapBeamDefaultPropertiesQuery, GetCapBeamDefaultPropertiesQueryVariables>;
export const GetPileDefaultPropertiesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPileDefaultProperties"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pileDefaultProperties"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"length"}},{"kind":"Field","name":{"kind":"Name","value":"row"}},{"kind":"Field","name":{"kind":"Name","value":"wharf"}},{"kind":"Field","name":{"kind":"Name","value":"headDiameter"}},{"kind":"Field","name":{"kind":"Name","value":"embedmentDepth"}}]}}]}}]} as unknown as DocumentNode<GetPileDefaultPropertiesQuery, GetPileDefaultPropertiesQueryVariables>;
export const GetAssetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAsset"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"type"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AssetType"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"asset"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"assetType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"type"}}},{"kind":"Argument","name":{"kind":"Name","value":"assetId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"openspaceLink"}},{"kind":"Field","name":{"kind":"Name","value":"coordinates"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"latitude"}},{"kind":"Field","name":{"kind":"Name","value":"longitude"}}]}}]}}]}}]} as unknown as DocumentNode<GetAssetQuery, GetAssetQueryVariables>;
export const GetPilesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPiles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"piles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"row"}},{"kind":"Field","name":{"kind":"Name","value":"bent"}},{"kind":"Field","name":{"kind":"Name","value":"side"}},{"kind":"Field","name":{"kind":"Name","value":"wharf"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetPilesQuery, GetPilesQueryVariables>;
export const GetCapBeamsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCapBeams"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"capBeams"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rowSpan"}},{"kind":"Field","name":{"kind":"Name","value":"bent"}},{"kind":"Field","name":{"kind":"Name","value":"side"}},{"kind":"Field","name":{"kind":"Name","value":"wharf"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetCapBeamsQuery, GetCapBeamsQueryVariables>;
export const DeleteCapBeamDefaultPropertyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteCapBeamDefaultProperty"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteCapBeamDefaultProperty"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<DeleteCapBeamDefaultPropertyMutation, DeleteCapBeamDefaultPropertyMutationVariables>;
export const NewCapBeamDefaultPropertyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"NewCapBeamDefaultProperty"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CapBeamDefaultPropertyInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createCapBeamDefaultProperty"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<NewCapBeamDefaultPropertyMutation, NewCapBeamDefaultPropertyMutationVariables>;
export const NewPileDefaultPropertyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"NewPileDefaultProperty"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PileDefaultPropertyInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createPileDefaultProperty"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<NewPileDefaultPropertyMutation, NewPileDefaultPropertyMutationVariables>;
export const DeletePileDefaultPropertyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeletePileDefaultProperty"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deletePileDefaultProperty"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<DeletePileDefaultPropertyMutation, DeletePileDefaultPropertyMutationVariables>;
export const CreateInspectionFrequencyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateInspectionFrequency"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"InspectionFrequencyInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createInspectionFrequency"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreateInspectionFrequencyMutation, CreateInspectionFrequencyMutationVariables>;
export const DeleteInspectionFrequencyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteInspectionFrequency"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteInspectionFrequency"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<DeleteInspectionFrequencyMutation, DeleteInspectionFrequencyMutationVariables>;
export const CreateCapBeamLoadDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateCapBeamLoad"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CapBeamLoadInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createCapBeamLoad"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreateCapBeamLoadMutation, CreateCapBeamLoadMutationVariables>;
export const DeleteCapBeamLoadDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteCapBeamLoad"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteCapBeamLoad"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<DeleteCapBeamLoadMutation, DeleteCapBeamLoadMutationVariables>;
export const GetCapBeamLoadsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCapBeamLoads"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"capBeamLoads"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"bendingLoad"}},{"kind":"Field","name":{"kind":"Name","value":"shearLoad"}},{"kind":"Field","name":{"kind":"Name","value":"wharf"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode<GetCapBeamLoadsQuery, GetCapBeamLoadsQueryVariables>;
export const CreatePileLoadDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreatePileLoad"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PileLoadInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createPileLoad"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreatePileLoadMutation, CreatePileLoadMutationVariables>;
export const DeletePileLoadDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeletePileLoad"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deletePileLoad"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<DeletePileLoadMutation, DeletePileLoadMutationVariables>;
export const GetPileLoadsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPileLoads"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pileLoads"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"load"}},{"kind":"Field","name":{"kind":"Name","value":"wharf"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"row"}}]}}]}}]} as unknown as DocumentNode<GetPileLoadsQuery, GetPileLoadsQueryVariables>;
export const CreateCapBeamDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateCapBeam"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CapBeamInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createCapBeam"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreateCapBeamMutation, CreateCapBeamMutationVariables>;
export const CreatePileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreatePile"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PileInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createPile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreatePileMutation, CreatePileMutationVariables>;
export const DeleteDocumentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteDocument"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteDocument"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const GetDocumentsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetDocuments"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"assetType"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AssetType"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"documents"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"assetId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"assetType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"assetType"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"uploadedBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"uploadedDate"}},{"kind":"Field","name":{"kind":"Name","value":"fileUrl"}},{"kind":"Field","name":{"kind":"Name","value":"context"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<GetDocumentsQuery, GetDocumentsQueryVariables>;
export const CreateDocumentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateDocument"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DocumentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createDocument"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"fileUrl"}}]}}]}}]} as unknown as DocumentNode<CreateDocumentMutation, CreateDocumentMutationVariables>;
export const CreateNoteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateNote"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"NoteInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createNote"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"images"}}]}}]}}]} as unknown as DocumentNode<CreateNoteMutation, CreateNoteMutationVariables>;
export const DeleteNoteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteNote"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteNote"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<DeleteNoteMutation, DeleteNoteMutationVariables>;
export const GetPileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPile"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"condition"}},{"kind":"Field","name":{"kind":"Name","value":"inspectionRequired"}}]}}]}}]} as unknown as DocumentNode<GetPileQuery, GetPileQueryVariables>;
export const GetCapBeamDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getCapBeam"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"capBeam"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"condition"}},{"kind":"Field","name":{"kind":"Name","value":"inspectionRequired"}}]}}]}}]} as unknown as DocumentNode<GetCapBeamQuery, GetCapBeamQueryVariables>;
export const InviteUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"InviteUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"role"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"inviteUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}},{"kind":"Argument","name":{"kind":"Name","value":"role"},"value":{"kind":"Variable","name":{"kind":"Name","value":"role"}}}]}]}}]} as unknown as DocumentNode<InviteUserMutation, InviteUserMutationVariables>;
export const UpdateUserRoleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateUserRole"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"role"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateUserRole"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}},{"kind":"Argument","name":{"kind":"Name","value":"role"},"value":{"kind":"Variable","name":{"kind":"Name","value":"role"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;
export const GetUsersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUsers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"port"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"users"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]}}]} as unknown as DocumentNode<GetUsersQuery, GetUsersQueryVariables>;
export const GetAverageConditionMetricsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAverageConditionMetrics"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"wharves"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"assetTypes"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AssetType"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"averageConditionMetric"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"wharves"},"value":{"kind":"Variable","name":{"kind":"Name","value":"wharves"}}},{"kind":"Argument","name":{"kind":"Name","value":"assetTypes"},"value":{"kind":"Variable","name":{"kind":"Name","value":"assetTypes"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"condition"}}]}}]}}]} as unknown as DocumentNode<GetAverageConditionMetricsQuery, GetAverageConditionMetricsQueryVariables>;
export const GetOverviewMetricDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetOverviewMetric"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"wharves"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"assetTypes"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AssetType"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"overviewMetric"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"wharves"},"value":{"kind":"Variable","name":{"kind":"Name","value":"wharves"}}},{"kind":"Argument","name":{"kind":"Name","value":"assetTypes"},"value":{"kind":"Variable","name":{"kind":"Name","value":"assetTypes"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"count"}}]}}]}}]} as unknown as DocumentNode<GetOverviewMetricQuery, GetOverviewMetricQueryVariables>;
export const GetRequiredInspectionsMetricDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetRequiredInspectionsMetric"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"wharves"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"assetTypes"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AssetType"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"requiredInspectionsMetric"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"wharves"},"value":{"kind":"Variable","name":{"kind":"Name","value":"wharves"}}},{"kind":"Argument","name":{"kind":"Name","value":"assetTypes"},"value":{"kind":"Variable","name":{"kind":"Name","value":"assetTypes"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"required"}},{"kind":"Field","name":{"kind":"Name","value":"notRequired"}}]}}]}}]} as unknown as DocumentNode<GetRequiredInspectionsMetricQuery, GetRequiredInspectionsMetricQueryVariables>;