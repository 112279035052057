import { createSlice } from "@reduxjs/toolkit";
import { Asset } from "../gql/graphql";
import { PayloadAction } from "@reduxjs/toolkit";
import { AssetCollection } from "../assets";

interface ActiveAsset extends Pick<Asset, "name" | "id" | "openspaceLink"> {
  collection: AssetCollection;
}

interface ActiveAssetState {
  activeAsset: ActiveAsset | null;
}

const initialState: ActiveAssetState = { activeAsset: null };

export const activeAssetSlice = createSlice({
  name: "activeAsset",
  initialState,
  reducers: {
    setActiveAsset: (state, { payload }: PayloadAction<ActiveAsset>) => {
      state.activeAsset = payload;
    },
  },
});

export default activeAssetSlice.reducer;
export const { setActiveAsset } = activeAssetSlice.actions;
export const selectActiveAsset = (state: { activeAsset: ActiveAssetState }) =>
  state.activeAsset.activeAsset;
export type { ActiveAsset };
